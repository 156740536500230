"use strict";

/**
 * инициализация всех инициализаций
 */
document.addEventListener("DOMContentLoaded", function () {
  global.init();
  additional.init();
});

/**
 * основной объект
 * @type {object}
 */
var global = {
  /**
   * вызов функций, которые должны запускаться при загрузке страницы
   */
  init() {
    this.preloadCss();
    this.header.stickCall();
    this.header.dropdownShowSubList();
    this.header.blinkingAnchors();
    this.tabs();
    this.tabsSmall();
    this.selects();
    //this.youtubeLazy();
    //this.rangeSlider();
    this.sliders.init();
    this.scrollTop.init();
    Maska.create("._masked");
  },

  /**
   * Предзагрузка CSSдля ускорения сайта и, при этом, избежания негативных эффектов для SEO
   * Отсюда: https://jross.me/asynchronous-and-progressive-css-loading/
   */
  preloadCss: function () {
    const decodeHTML = function (html) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = html;
      return textarea.value;
    };
    const getItemsFromContainerText = function (container, selector) {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(
        decodeHTML(container.textContent),
        "text/html"
      );

      return parsedHtml.querySelectorAll(selector);
    };
    function loadCss() {
      const cssContainers = document.querySelectorAll(
        "noscript[data-css-lazyload]"
      );
      if (!cssContainers) {
        return;
      }
      const styleSheets = document.createDocumentFragment();
      for (const cssContainer of cssContainers) {
        const sheets = getItemsFromContainerText(
          cssContainer,
          'link[rel="stylesheet"]'
        );
        styleSheets.append(...sheets);
        cssContainer.remove();
      }
      document.head.append(styleSheets);
    }
    loadCss();
  },

  popups: {
    showPopup: function (popup) {
      let $overlay = document.querySelector("._overlay"),
        $popup = document.querySelector(popup);
      $overlay.classList.add("_show");
      $popup.classList.add("_show");
    },
    closePopup: function () {
      let $overlay = document.querySelector("._overlay"),
        $popups = document.querySelectorAll(".popup");

      for (var i = 0; i < $popups.length; i++) {
        $popups[i].classList.remove("_show");
      }
      $overlay.classList.remove("_show");
      // $('body').css('overflow', 'visible');
    },
  },

  scrollTop: {
    init: function () {
      window.onscroll = function () {
        global.scrollTop.scrollFunction();
      };
    },
    scrollFunction: function () {
      var button = document.querySelector(".scroll-top");

      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      )
        button.style.display = "block";
      else button.style.display = "none";
    },
    topFunction: function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },

  header: {
    // прилипание меню
    stick(instance) {
      let $catalog = document.querySelector("._headerCatalog"),
        $body = document.querySelector("body"),
        catalogHeight = $catalog.offsetHeight + "px",
        catalogOffset = 157;

      if (
        instance.scrollY > catalogOffset &&
        $catalog.classList.contains("header-catalog--fixed")
      )
        return;

      if (instance.scrollY > catalogOffset) {
        $catalog.classList.add("header-catalog--fixed");
        $body.style.paddingTop = catalogHeight;
      } else {
        $catalog.classList.remove("header-catalog--fixed");
        $body.style.paddingTop = 0;
      }
    },

    // вызов прилипания при загрузке страницы и при скролле, если устройство не мобильное
    stickCall() {
      if (document.documentElement.clientWidth < 1199) return;

      this.stick(window);

      window.addEventListener("scroll", () => {
        this.stick(window);
      });
    },

    // появляние подменю в меню каталога
    dropdownShowSubList() {
      const $triggerList = document.querySelectorAll("._subListTrigger"),
        $subList = document.querySelectorAll("._subList");
      var itemsCountDiv = 0;
      var $menuLevel_1 = $(".header-catalog-dropdown._catalogDropdown");
      var $menuHeight = $menuLevel_1.outerHeight(true);
      /*
						//Выставляем высоту второго блока
						$(".header-catalog-dropdown__sublist._subList").each(function(){
							var $menuTop = $menuLevel_1.offset().top; // position = { left: 42, top: 567 }
							var $d = $menuLevel_1[0].getBoundingClientRect().top;
							$(this).height($menuHeight);
							$(this).css({ top: $menuTop + 'px' });
						});
			*/

      $("._subListTrigger").each(function () {
        //Убираем стрелочки с пустых категорий
        if ($(this).find("._subList > li").length == 0)
          $(this).find(".header-catalog-dropdown__list-item-icon").outerHTML =
            "";
      });

      for (let i = 0; i < $triggerList.length; i++) {
        $triggerList[i].addEventListener("mouseenter", () => {
          $subList.forEach((elem) => {
            elem.classList.remove("is-active");
          });
          $subList[i].classList.add("is-active");
          //itemsCountDiv = $subList[i].querySelector('.sublist-items-count');
          //Показываем панель подкатегорий только если список не пустой
          //if (itemsCountDiv.getAttribute('data-count') > 0)
          //$subList[i].classList.add('is-active');

          //Выставляем высоту второго блока
          /*
					var $menuTop = $menuLevel_1.offset().top; // position = { left: 42, top: 567 }
					var rect = $subList[i].getBoundingClientRect();
					var bodyRect = document.body.getBoundingClientRect(),
					elemRect = $subList[i].getBoundingClientRect(),
					offset   = elemRect.top - bodyRect.top - $menuTop;
					
					$subList[i].style.top = '-' + offset + 'px';
					$subList[i].style.height = $menuHeight + 'px';
*/
        });

        $triggerList[i].addEventListener("mouseleave", () => {
          $subList.forEach((elem) => {
            elem.classList.remove("is-active");
          });
        });
      }
    },

    // активность поля для поиска и появление дропдауна с результатами
    search(instance) {
      if (instance.value && instance === document.activeElement) {
        //document.querySelector('._searchDropdown').classList.add('is-active');
        document.querySelector("._search").classList.add("g-search--has-value");
      } else {
        //document.querySelector('._searchDropdown').classList.remove('is-active');
        setTimeout(
          () =>
            document
              .querySelector("._search")
              .classList.remove("g-search--has-value"),
          100
        );
      }
    },

    // очистить поле поиска
    searchClear() {
      document.querySelector("._searchInput").value = "";
      // this.search('._searchInput');
    },

    //Мигающие ссылки (непрочитанные новости/акции, важные ссылки и т.п.)
    blinkingAnchors() {
      const blinkedElements = document.querySelectorAll("a.blinked-text");
      blinkedElements.forEach((e) => {
        const href = e.getAttribute("href");
        if (href) {
          //Если посетитель уже переходил по ссылке, то выключаем мигание (на 10 дней)
          if (getCookie(href) == "nonblinking") {
            //console.log(e);
            e.classList.remove("blinked-text");
          } else {
            //Иначе слушаем как он перейдет по ссылке
            e.addEventListener("click", () => {
              setCookie(href, "nonblinking", 10);
            });
          }
        }
      });
    },
  },
 
  callbackOrder() {
    //Если есть кнопка (загружена библиотека)
	if (document.querySelector(".button-widget-open")) {
    //if ($(".button-widget-open").length) {
      $(".button-widget-open").delay(500).click(); //Вызов формы обратного звонка Манго Офис (mango)
    } else {
      //Кнопки нет, значит библиотека не загружена. Загружаем (взял из mango'вского скрипта)
      !(function (t) {
        function e() {
          i = document.querySelectorAll(".button-widget-open");
          for (var e = 0; e < i.length; e++)
            "true" != i[e].getAttribute("init") &&
              ((options = JSON.parse(
                i[e].closest("." + t).getAttribute("data-settings")
              )),
              i[e].setAttribute(
                "onclick",
                "alert('" + options.errorMessage + "(0000)'); return false;"
              ));
        }
        function o(t, e, o, n, i, r) {
          var s = document.createElement(t);
          for (var a in e) s.setAttribute(a, e[a]);
          s.readyState
            ? (s.onreadystatechange = o)
            : ((s.onload = n), (s.onerror = i)),
            r(s);
        }
        function n() {
          for (var t = 0; t < i.length; t++) {
            var e = i[t];
            if ("true" != e.getAttribute("init")) {
              options = JSON.parse(e.getAttribute("data-settings"));
              var o = new MangoWidget({
                host: window.location.protocol + "//" + options.host,
                id: options.id,
                elem: e,
                message: options.errorMessage,
              });
              o.initWidget(),
                e.setAttribute("init", "true"),
                i[t].setAttribute("onclick", "");
            }
          }
        }
        host = window.location.protocol + "//widgets.mango-office.ru/";
        var i = document.getElementsByClassName(t);
        o(
          "link",
          {
            rel: "stylesheet",
            type: "text/css",
            href: host + "css/widget-button.css",
          },
          function () {},
          function () {},
          e,
          function (t) {
            document.documentElement.insertBefore(
              t,
              document.documentElement.firstChild
            );
          }
        ),
          o(
            "script",
            {
              type: "text/javascript",
              src: host + "widgets/mango-callback.js",
            },
            function () {
              ("complete" == this.readyState || "loaded" == this.readyState) &&
                n();
            },
            n,
            e,
            function (t) {
              document.documentElement.appendChild(t);
            }
          );
      })("mango-callback");

      $(this)
        .delay(500)
        .queue(function () {
          $(".button-widget-open").click(); //Вызов формы обратного звонка Манго Офис (mango)
        });
    }
  },

  // переключение класса активности у передаваемых элементов
  toggle(instance, elem) {
    if (instance) instance.classList.toggle("is-active");

    if (elem) document.querySelector(elem).classList.toggle("is-active");
  },

  // позиционирование всплывающих подсказок
  /*
	tooltip(instance, target, position)
	{
		if (!instance)
			return;
		if(target) {
			let targetElement = document.querySelector(target);
			let targetRect = targetElement.getBoundingClientRect();
			
			let bodyRect = document.body.getBoundingClientRect(),
				instanceRect = instance.getBoundingClientRect(),
				offsetTop   = instanceRect.top - bodyRect.top + instanceRect.height,
				offsetLeft  = instanceRect.left - bodyRect.left + instanceRect.width/2
				- targetRect.width/2;
			console.log(`instanceRect: ${instanceRect.top}, ${instanceRect.left}, ${instanceRect.height}, ${instanceRect.width}`);
			let instanceWB_top = instanceRect.top - bodyRect.top;
			let instanceWB_left = instanceRect.left - bodyRect.left;
			console.log(`instanceRect w body: ${instanceWB_top}, ${instanceWB_left}`);
			console.log(`targetRect: ${targetRect.top}, ${targetRect.left}, ${targetRect.height}, ${targetRect.width}`);
			console.log('offset. top:' + offsetTop + ', left: ' + offsetLeft);
			
			//targetElement.style.top = offsetTop + 'px';
			//targetElement.style.left = instanceWB_left + 'px';
			//instance.classList.toggle('is-active');
		}

		if(position) {
			if (position === 'bottom') {
				
			}
			//document.querySelector(elem).classList.toggle('is-active');
		}
	},
	*/

  showSearchMobile(instance, elem, href) {
    //Для мобильных поиск будет только на странице, без всплывающих панелей
    /*if (getWidth() <= 767)
		{
			window.location.href = href;
		}
		else
		{*/
    global.scrollTop.topFunction();
    global.toggle(instance, elem);
    document.querySelector(elem).querySelector('input[name="query"]').focus();
    //}
  },

  // получить соседние элементы
  getSiblings(e) {
    let siblings = [];
    // if no parent, return no sibling
    if (!e.parentNode) return siblings;

    // first child of the parent node
    let sibling = e.parentNode.firstChild;

    // collecting siblings
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== e) siblings.push(sibling);

      sibling = sibling.nextSibling;
    }

    return siblings;
  },

  // инициализировать все табы на странице
  tabs() {
    const $btns = document.querySelectorAll("[data-nav-for]");
    let $tabs = document.querySelectorAll("[data-tab]");

    const $btns2 = document.querySelectorAll("[data-nav-for2]");
    const $tabs2 = document.querySelectorAll("[data-tab2]");

    if (!$btns.length) return;

    for (let i = 0; i < $btns.length; i++) {
      $btns[i].addEventListener("click", function () {
        global.getSiblings(this).forEach((elem) => {
          elem.classList.remove("is-active");
        });
        this.classList.add("is-active");

        if (this.getAttribute("data-nav-group") != null) {
          $tabs = document.querySelectorAll(
            `[data-tab-group='${this.getAttribute("data-nav-group")}']`
          );
        }

        $tabs.forEach((elem) => {
          elem.classList.remove("is-active");
          if (
            elem.getAttribute("data-tab") ===
            $btns[i].getAttribute("data-nav-for")
          )
            elem.classList.add("is-active");
        });
      });
    }

    if (!$btns2.length) return;

    for (let i = 0; i < $btns2.length; i++) {
      $btns2[i].addEventListener("click", function () {
        global.getSiblings(this).forEach((elem) => {
          elem.classList.remove("is-active");
        });
        this.classList.add("is-active");

        $tabs2.forEach((elem) => {
          elem.classList.remove("is-active");

          if (
            elem.getAttribute("data-tab2") ===
            $btns2[i].getAttribute("data-nav-for2")
          )
            elem.classList.add("is-active");
        });
      });
    }
  },

  /**Табы на странице товара под ценой */
  tabsSmall() {
    const tabLabels = document.querySelectorAll(".g-tab-small-label");
    const tabContents = document.querySelectorAll(".g-tab-small-content");
    const bars = document.querySelectorAll(".product__metrics-bar");

    tabLabels[0]?.classList.add("active");
    tabContents[0]?.classList.add("active");
    tabLabels.forEach((tabLabel) => {
      tabLabel.addEventListener("click", function () {
        const tabNumber = this.dataset.tab;

        tabLabels.forEach((label) => {
          label.classList.remove("active");
        });

        tabContents.forEach((content) => {
          content.classList.remove("active");
        });

        this.classList.add("active");
        document
          .querySelector(`.g-tab-small-content[data-tab="${tabNumber}"]`)
          .classList.add("active");
      });
    });

    //Патч для того, чтобы подсказки не вылазили за границы экрана
    bars.forEach((bar) => {
      bar.addEventListener("mouseenter", function () {
        const caption = this.querySelector(".product__metrics-bar-caption");
        const rect = this.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        if (rect.left < viewportWidth / 2) {
          // If the bar is in the left half of the viewport
          caption.style.left = "0";
          caption.style.transform = "translateX(0)";
        } else {
          // If the bar is in the right half of the viewport
          caption.style.right = "0";
          caption.style.transform = "translateX(0)";
          caption.style.left = "auto";
        }
      });

      bar.addEventListener("mouseleave", function () {
        const caption = this.querySelector(".product__metrics-bar-caption");
        caption.style.left = "50%";
        caption.style.right = "auto";
        caption.style.transform = "translateX(-50%)";
      });
    });
  },

  youtubeLazy() {
    let $youtube = document.querySelectorAll("._youtube");

    for (let i = 0; i < $youtube.length; i++) {
      let source =
        "https://img.youtube.com/vi/" +
        $youtube[i].dataset.embed +
        "/hqdefault.jpg";

      let image = new Image();
      image.src = source;
      image.addEventListener(
        "load",
        function () {
          $youtube[i].appendChild(image);
        }.bind(this, i)
      );

      $youtube[i].addEventListener("click", function () {
        let iframe = document.createElement("iframe");
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "");
        iframe.setAttribute(
          "src",
          "https://www.youtube.com/embed/" +
            this.dataset.embed +
            "?rel=0&showinfo=0&autoplay=1"
        );

        this.innerHTML = "";
        this.appendChild(iframe);
      });
    }
  },

  // инициализировать все селекты на странице
  selects() {
    const $select = document.querySelectorAll("._selectBtn"),
      $option = document.querySelectorAll("._selectOption");
    let index = 1;

    if (!$select.length) return;

    $select.forEach((a) => {
      a.addEventListener("click", (b) => {
        b.target.parentElement.classList.toggle("is-active");
      });
    });

    $option.forEach((a) => {
      a.addEventListener("click", (b) => {
        const parent = b.target.closest("._select");

        parent.classList.remove("is-active");

        if (b.target.hasAttribute("data-value"))
          parent.children[0].setAttribute(
            "data-value",
            b.target.getAttribute("data-value")
          );

        parent.children[0].innerText = b.target.innerText;
      });
    });
  },

  rangeSlider() {
    const $productLimitSlider = document.querySelector("._priceRange"),
      $minValueInput = document.querySelector("._price0"),
      $maxValueInput = document.querySelector("._price1");

    if (!$productLimitSlider) return;

    noUiSlider.create($productLimitSlider, {
      start: [$minValueInput.value, $maxValueInput.value],
      connect: true,
      tooltips: false,
      step: 1,
      range: {
        min: 50,
        max: 11547,
      },

      format: {
        from: function (value) {
          return parseInt(value);
        },

        to: function (value) {
          return parseInt(value);
        },
      },
    });

    $productLimitSlider.noUiSlider.on("update", function (values, handle) {
      $minValueInput.value = values[0];
      $maxValueInput.value = values[1];
    });

    $minValueInput.addEventListener("input", function () {
      $productLimitSlider.noUiSlider.set([this.value, null]);
    });

    $maxValueInput.addEventListener("input", function () {
      $productLimitSlider.noUiSlider.set([null, this.value]);
    });
  },

  sliders: {
    init() {
      this.productsCarousel();
      //this.catalogProductSliders();
      /*this.productSlider();*/
      //this.productPhotoCarousel();
      this.collectionCarousel();
      this.reviewsCarousel();
      this.suppliersCarousel();
      this.videoGalleryCarousel();
    },

    productsCarousel() {
      if (document.documentElement.clientWidth > 1023) return;

      let $productsCarousel = document.querySelectorAll("._productsCarousel");

      if (!$productsCarousel.length) return;

      $productsCarousel.forEach((elem) => {
        let rows = elem.getAttribute("data-rows")
          ? elem.getAttribute("data-rows")
          : 1;
        new Splide(elem, {
          perPage: 3,
          perMove: 1,
          pagination: true,
          lazyLoad: "nearby",
          gap: "30px",
          grid: {
            rows: rows,
            cols: 1,
            gap: {
              row: "20px",
            },
          },
          breakpoints: {
            479: {
              perPage: 2,
              perMove: 1,
              gap: "12px",
              pagination: true,

              grid: {
                gap: {
                  row: "10px",
                },
              },
            },
          },
        }).mount(window.splide.Extensions);
      });
    },

    catalogProductSliders() {
      const $catalogProduct = document.querySelectorAll("._catalogProduct");

      if (!document.querySelectorAll("._catalogProduct").length) return;

      $catalogProduct.forEach((elem) => {
        let $catalogProductSlider = new Splide(
          elem.querySelector("._catalogProductSlider"),
          {
            rewind: true,
            heightRatio: 0.5,
            pagination: false,
            cover: true,
            lazyLoad: "nearby",
            fixedWidth: "340px",
            fixedHeight: "340px",

            breakpoints: {
              1099: {
                fixedWidth: "280px",
                fixedHeight: "280px",
              },

              1099: {
                fixedWidth: "290px",
                fixedHeight: "290px",
              },
            },
          }
        );

        let images = elem.querySelectorAll("._catalogProductThumbs li");

        let activeImage;
        let activeClass = "is-active";

        for (let i = 0, len = images.length; i < len; i++) {
          let image = images[i];

          $catalogProductSlider.on(
            "click",
            function () {
              if (activeImage !== image) {
                $catalogProductSlider.go(i);
              }
            },
            image
          );
        }

        $catalogProductSlider.on("mounted move", function (newIndex) {
          // newIndex will be undefined through the "mounted" event.
          let image = images[newIndex !== undefined ? newIndex : splide.index];

          if (image && activeImage !== image) {
            if (activeImage) {
              activeImage.classList.remove(activeClass);
            }

            global.getSiblings(image).forEach((elem) => {
              elem.classList.remove(activeClass);
            });

            image.classList.add(activeClass);
            activeImage = image;
          }
        });

        $catalogProductSlider.mount();
      });
    },

    productSlider() {
      if (!document.querySelectorAll("._product").length) return;

      let secondarySlider = new Splide("._productThumbs", {
        fixedWidth: "90px",
        fixedHeight: "90px",
        rewind: true,
        gap: 10,
        pagination: false,
        cover: true,
        isNavigation: true,

        breakpoints: {
          1023: {
            fixedWidth: "60px",
            fixedHeight: "60px",
            gap: 13,
          },
        },
      }).mount();

      let primarySlider = new Splide("._productSlider", {
        type: "fade",
        heightRatio: 0.5,
        fixedWidth: "470px",
        fixedHeight: "470px",
        pagination: false,
        arrows: false,
        cover: true,
        lazyLoad: "nearby",
        isNavigation: true,

        breakpoints: {
          1023: {
            fixedWidth: "280px",
            fixedHeight: "280px",
          },

          767: {
            fixedWidth: "290px",
            fixedHeight: "290px",
          },
        },
      });

      primarySlider.sync(secondarySlider).mount();
    },

    productPhotoCarousel() {
      if (!document.querySelectorAll("._productPhotoCarousel").length) return;

      new Splide("._productPhotoCarousel", {
        perPage: 4,
        perMove: 1,
        fixedWidth: "270px",
        fixedHeight: "214px",
        cover: true,
        lazyLoad: "nearby",
        gap: "30px",
        pagination: false,
        // breakpoints: {
        // 	1023: {
        // 		perPage: 1,
        // 		perMove: 1,
        // 		fixedWidth: '575px',
        // 	},

        // 	600:
        // 	{
        // 		perPage: 1,
        // 		perMove: 1,
        // 		fixedWidth: 0
        // 	}
        // }
      }).mount();
    },

    collectionCarousel() {
      if (!document.querySelectorAll("._collectionCarousel").length) return;

      new Splide("._collectionCarousel", {
        perPage: 4,
        perMove: 1,
        lazyLoad: "nearby",
        gap: "30px",
        pagination: false,
        breakpoints: {
          1023: {
            perPage: 3,
            perMove: 1,
          },

          600: {
            perPage: 2,
            perMove: 1,
            gap: "12px",
            pagination: true,
          },
        },
      }).mount();
    },

    reviewsCarousel() {
      if (!document.querySelectorAll("._reviewsCarousel").length) return;

      new Splide("._reviewsCarousel", {
        perPage: 2,
        perMove: 1,
        lazyLoad: "nearby",
        gap: "30px",
        breakpoints: {
          1023: {
            perPage: 1,
            perMove: 1,
            fixedWidth: "575px",
          },

          600: {
            perPage: 1,
            perMove: 1,
            fixedWidth: 0,
          },
        },
      }).mount();
    },

    videoGalleryCarousel() {
      if (!document.querySelectorAll("._videoGalleryCarousel").length) return;

      new Splide("._videoGalleryCarousel", {
        perPage: 4,
        perMove: 4,
        lazyLoad: "nearby",
        gap: "30px",
        breakpoints: {
          1023: {
            perPage: 3,
            perMove: 3,
          },

          600: {
            perPage: 2,
            perMove: 2,
            fixedWidth: "135px",
            gap: "12px",
          },
        },
      }).mount();
    },

    suppliersCarousel() {
      if (!document.querySelectorAll("._suppliersCarousel").length) return;

      new Splide("._suppliersCarousel", {
        perPage: 4,
        perMove: 4,
        pagination: false,
        cover: true,
        lazyLoad: "nearby",
        fixedWidth: "270px",
        fixedHeight: "268px",
        gap: "30px",
        grid: {
          rows: 2,
          cols: 1,
          gap: {
            row: "20px",
          },
        },
        breakpoints: {
          1023: {
            perPage: 2,
            perMove: 2,
            pagination: true,
          },

          479: {
            fixedWidth: "156px",
            fixedHeight: "152px",
            perPage: 2,
            perMove: 2,
            gap: "10px",
            pagination: true,

            grid: {
              gap: {
                row: "8px",
              },
            },
          },
        },
      }).mount(window.splide.Extensions);
    },
  },
};

//Для переключения табов вкарточке товара по клику на ссылки типа "полное описание", "все характеристики" и т.д.
var tabs = {
  activateByButton(tabId, buttonId) {
    //Табы
    if (getWidth() > 767) {
      const $btns = document.querySelectorAll("[data-nav-for]");
      const $tabs = document.querySelectorAll("[data-tab]");
      if (!$btns.length) return;

      $btns.forEach((btn) => {
        btn.classList.remove("is-active");
      });
      var button = document.getElementById(tabId);
      button.classList.add("is-active");

      $tabs.forEach((tab) => {
        tab.classList.remove("is-active");
        if (
          tab.getAttribute("data-tab") === button.getAttribute("data-nav-for")
        )
          tab.classList.add("is-active");
      });
    }
    //кнопки - раскрывающие блок - аккордеон
    else {
      var button = "#" + buttonId;
      $(button).click();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(button).offset().top,
        },
        400
      );
    }
  },
};

var additional = {
  /**
   * вызов функций, которые должны запускаться при загрузке страницы
   */
  init() {
    this.roistat();
    this.tooltips();
    this.sort();
    this.fixsome();
    this.votingInit();
    this.productPriceBlockInit();
    this.installmentInit();
    this.deliveryInit();
    this.basketInit();

    //ФИЛЬТРЫ
    //Показываем кнопки "Показать еще", если высота блока(или количество элементов) больше стольки-то
    var itemsCountLimit = 5; //количество элементов фильтра, в сокращенном виде
    var itemsContainerHeight = "400px"; //максимальная высота блока с элементами фильтра
    let $filterBodyHidden = false;
    const $filterBody = $(".filters._filtersOptions");
    if ($filterBody) {
      if (!$filterBody.hasClass("is-active")) $filterBodyHidden = true;
      if ($filterBodyHidden) $filterBody.addClass("is-active");
    }
    setTimeout(function () {}, 1);
    $(
      ".catalog__sidebar ul.filters._filtersOptions .filters__item .filters__item-content"
    ).each(function () {
      $hidden = false;
      $filterHead = $(this).parent().find(".filters__item-head");

      //Принудительно раскрываем фильтры, в которых указаны значения
      if ($(this).find("input[type=checkbox]:checked").length > 0)
        $filterHead.addClass("is-active");

      if (!$filterHead.hasClass("is-active")) $hidden = true;

      if (
        $(this).find(".filters__list .filters__list-item").length >
        itemsCountLimit
      ) {
        var totalHeight = 0;
        $(this).find(".g-show-more").removeClass("disabled");
        if ($hidden) $filterHead.addClass("is-active");
        $(this)
          .find(".filters__list .filters__list-item")
          .each(function (index, element) {
            if (index > itemsCountLimit - 1) return;
            totalHeight += $(this).outerHeight(true);
          });
        if ($hidden) $filterHead.removeClass("is-active");
        var filterList = $(this).find(".filters__list");
        filterList.css({ "max-height": totalHeight + "px" });
        filterList.attr("data-max-height", totalHeight + "px");
      }
    });
    $(".catalog__sidebar ul.filters._filtersOptions .g-show-more").each(
      function () {
        $(this).on("click", function (event) {
          var filterList = $(this).parent().find(".filters__list");
          if ($(this).hasClass("is-active")) {
            //показываем
            filterList.css({
              maxHeight: filterList.attr("data-max-height"),
              "overflow-y": "hidden",
            });
            $(this).removeClass("is-active");
          } else {
            //Скрываем
            filterList.css({
              maxHeight: itemsContainerHeight,
              "overflow-y": "auto",
            });
            $(this).addClass("is-active");
          }

          event.preventDefault();
        });
      }
    );
    if ($filterBodyHidden) $filterBody.removeClass("is-active");

    //Блоки с товарами
    $("._productsCarousel").each(function () {
      //Если нет товаров в блоке, то скрываем весь блок
      if ($(this).find(".splide__list").children().length == 0)
        $(this).closest(".products").css({ display: "none" });
    });

    $(".header-catalog__dropdown-wrap .g-btn").click(function () {
      var $menuLevel_1 = $(".header-catalog-dropdown._catalogDropdown");
      var $menuHeight = $menuLevel_1.outerHeight(true);
      var $menuTop = $menuLevel_1.offset().top; // position = { left: 42, top: 567 }
      $(".header-catalog-dropdown__sublist._subList").each(function () {
        var $parTop = $(this).parent().offset().top;
        $(this).css({ top: $menuTop - $parTop + "px" });
        $(this).css({ height: $menuHeight + "px" });
      });
    });

    $(".product-doc .product-doc__open").click(function () {
      ym(20009113, "reachGoal", "TOVAR_CARD_DOCS_CLICK", {
        doc_ref: $(this).attr("href"),
        ref_type: "open",
      });
    });
    $(".product-doc .product-doc__download").click(function () {
      ym(20009113, "reachGoal", "TOVAR_CARD_DOCS_CLICK", {
        doc_ref: $(this).attr("href"),
        ref_type: "download",
      });
    });

    //Popup окна. Нужна библиотека jquery.cookie.min.js
    /*
		if($.cookie('popup') != 'seen'){
			$.cookie('popup', 'seen', { expires: 1, path: '/' }); // дней
			setTimeout(function() {
				global.popups.showPopup('.popup-action__type-square');
			}, 10000);
		};
		*/

    //Скрытие верхней панели в каталоге, если нет ссылок
    let catTopPanel = document.querySelector(".catalog-top-panel");
    if (catTopPanel) {
      let catContent = catTopPanel.querySelector(".catalog-top-panel-content");
      //if (catContent.children.length === 0 || document.documentElement.clientWidth < 1199)
      if (catContent.children.length === 0 || getWidth() < 1099)
        catTopPanel.classList.remove("is-active");
    }
  },

  roistat() {
    //Проставляем номера визитов roistat во все соответствующие input'ы на странице
    window.onRoistatAllModulesLoaded = function () {
      window.roistat.registerOnVisitProcessedCallback(function () {
        window.roistatVisitId = window.roistat.getVisit();
        window.roistatSource = window.roistat.getSource();
        //direct1_{source_type}_{banner_id}_{keyword}
        //seo_yandex_
        const roistatInputs = document.querySelectorAll(
          "input[name='roistat']"
        );
        roistatInputs.forEach((i) => {
          i.value = window.roistatVisitId;
        });
        if (window.roistatSource) {
          const [channel, source, bannerId, keyword] =
            window.roistatSource.split("_");
          window.roistatSourceChannel = channel == "seo" ? "organic" : channel;
          window.roistatSourceSource = source;
          window.roistatSourceBannerId = bannerId;
          window.roistatSourceKeyword = keyword;
          const formUtmMedium = document.querySelectorAll(
            "input[name='utm_medium']"
          );
          formUtmMedium.forEach((i) => {
            if (i.value == "") {
              i.value = window.roistatSourceChannel;
            }
          });
          const formUtmSource = document.querySelectorAll(
            "input[name='utm_source']"
          );
          formUtmSource.forEach((i) => {
            if (i.value == "") {
              i.value = window.roistatSourceSource;
            }
          });
        }
      });
    };
  },

  //Всплываюшие подсказки (popper js)
  tooltips() {
    //Собираем все элементы с запоненным атрибутом селектора блока всплаывающей подсказки
    const buttons = document.querySelectorAll("[data-popper-tooltip]");
    if (buttons.length) {
      const popperInstances = [],
        tooltipInstances = [];

      //Показ tooltip'а
      const show = (popperId) => {
        const tooltip = tooltipInstances[popperId];
        tooltip.setAttribute("data-show", "");

        // Enable the event listeners
        const popperInstance = popperInstances[popperId];
        popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: "eventListeners", enabled: true },
          ],
        }));
        popperInstance.update();
      };

      const hide = (popperId, force = false) => {
        setTimeout(function () {
          const tooltip = tooltipInstances[popperId];
          if (!tooltip) return;

          if (force) {
            tooltip.removeAttribute("data-keep");
          }
          //Если курсор над tooltip, то не закрываем
          if (tooltip.hasAttribute("data-keep")) return;

          tooltip.removeAttribute("data-show");
          // Disable the event listeners
          const popperInstance = popperInstances[popperId];
          popperInstance.setOptions((options) => ({
            ...options,
            modifiers: [
              ...options.modifiers,
              { name: "eventListeners", enabled: false },
            ],
          }));
        }, 200);
      };

      //Это для того, чтобы при переведении курсора на сам tooltip он не закрывался
      const keep = (popperId) => {
        const tooltip = tooltipInstances[popperId];
        tooltip.setAttribute("data-keep", "");
      };
      const keepNot = (popperId) => {
        const tooltip = tooltipInstances[popperId];
        tooltip.removeAttribute("data-keep");
        hide(popperId);
      };

      const showEvents = ["mouseenter", "focus"];
      const hideEvents = ["mouseleave", "blur"];

      buttons.forEach((button) => {
        const tooltipSelector = button.getAttribute("data-popper-tooltip");
        if (!tooltipSelector) return;
        const tooltip = document.querySelector(tooltipSelector);
        if (tooltip) {
          //Если нашли tooltip, то ок; нет - пропускаем этот
          const closeButton = tooltip.querySelector(".tooltip-close");
          //Создаем для каждого элемента и tooltip'а экземпляр popper
          const popperInstance = Popper.createPopper(button, tooltip, {
            strategy: "fixed",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 8],
                },
              },
            ],
          });
          //Для удобства доступа к элементам, собираем их в массивы (индекс получается общий)
          const popperId = popperInstances.push(popperInstance) - 1; //собираем popper'ы
          const tooltipId = tooltipInstances.push(tooltip) - 1; //собираем tooltip'ы

          showEvents.forEach((event) => {
            button.addEventListener(event, () => {
              show(popperId);
            }); //Слушаем hover на элементе (кнопке...)
            tooltip.addEventListener(event, () => {
              keep(popperId);
            }); //Слушаем hover мышки на сам tooltip'а
          });

          hideEvents.forEach((event) => {
            button.addEventListener(event, () => {
              hide(popperId);
            }); //...
            tooltip.addEventListener(event, () => {
              keepNot(popperId);
            }); //Убирание мышки с самого tooltip'а
          });
          if (closeButton) {
            //console.log(closeButton);
            closeButton.addEventListener("click", () => {
              hide(popperId, true);
            });
          }
        }
      });
    }

    const closeButtons = document.querySelectorAll(
      ".tooltip-block .close-popup-btn"
    );
    if (closeButtons) {
      closeButtons.forEach((b) => {
        b.addEventListener("click", (e) => {
          b.closest(".tooltip-block").removeAttribute("data-show");
        });
      });
    }
  },

  //Инициализация возможности сортировки
  sort() {
    const sortSelect = document.querySelector("#mse2_sort");
    const limitSelect = document.querySelector("#mse2_limit");
    //const tplButtonContainer = document.querySelectorAll('#mse2_tpl');
    const viewSelects = document.querySelectorAll("#mse2_tpl a");
    const results = document.querySelector(mSearch2.options["results"]);
    //const isTest = document.querySelector('#mse2_tpl').dataset.test;

    if (!sortSelect && !limitSelect && !viewSelects) {
      return;
    }

    //const classPrefix = tplButtonContainer.dataset['items-class-prefix'];

    //Заменил это на fenom проверку в шаблоне "tpl.mFilter2.outer.tfkk"
    /*
		//Берем параметры сортровки из GET и выставляем в select соответствующий option (из коробки не выставляется)
		const paramsUrl = mSearch2.Hash.get();
		const sortsUrl = paramsUrl.sort.split(mse2Config.values_delimeter); //Массив с сортировками (если их несколько)
		//Берем только первую сортировку, т.к. выбор сортировки сделан сейчас с помощью select'а с выбором единичной сортировки
		//Если в будущем нужна будет сложная множественная сортировка, то переделать тут
		const sortUrl = sortsUrl[0].split(mse2Config.method_delimeter); //Массив ['field', 'order'] (['price', 'asc'])
		const options = Array.from(sortSelect.options);
		const option = options.find(o => o.value === sortUrl[1] && o.dataset['sort'] === sortUrl[0]);
		option.selected = true;
		*/

    sortSelect &&
      sortSelect.addEventListener("change", (e) => {
        if (
          !e.target[e.target.selectedIndex].dataset ||
          !e.target[e.target.selectedIndex].dataset["sort"]
        ) {
          return;
        }
        let value = e.target.value;
        let sort = e.target[e.target.selectedIndex].dataset["sort"];
        sort += mse2Config.method_delimeter + value;
        mse2Config.sort = sort;
        mSearch2.submit({ ...mSearch2.getFilters(), ajax: true });
      });

    limitSelect &&
      limitSelect.addEventListener("change", (e) => {
        mse2Config.limit = e.target.value;
        mSearch2.submit({ ...mSearch2.getFilters(), ajax: true });
      });

    //Список классов, указанных для всех видов карточек
    const viewClasses = [...viewSelects]
      .map((v) => v.dataset.itemsClass)
      .filter(Boolean); //...и убираем undefined

    viewSelects.forEach((element) => {
      if (!element.dataset || !element.dataset["tpl"]) {
        return;
      }

      element.addEventListener("click", (e) => {
        e.preventDefault();
        mse2Config.tpl = e.currentTarget.dataset["tpl"];

        //Убираем все классы с контейнера с результатами, отвечающие за вид карточек
        results.classList.remove(viewClasses);
        if (e.currentTarget.dataset.itemsClass) {
          //Если есть, добавляем нужный класс в него
          results.classList.add(e.currentTarget.dataset.itemsClass);
        }

        mSearch2.submit({ ...mSearch2.getFilters(), ajax: true });
      });
    });
  },

  fixsome() {},

  /**
   * Initializes the voting feature on the page.
   * @returns {void} - Returns nothing.
   */
  votingInit() {
    class Feedback {
      static container;
      static form;
      static button;
      static stars;
      static cover;

      /**
       * Initializes the properties of the feedback form and star rating UI components.
       * @returns {boolean} True if all required elements are found, false otherwise.
       */
      static init() {
        this.container = document.querySelector(".catalog-feedback");
        this.form =
          this.container &&
          this.container.querySelector(".catalog-feedback-form");
        this.button =
          this.form && this.form.querySelector(".catalog-feedback-form button");
        this.stars =
          this.container && this.container.querySelector(".star-rating");
        this.cover = this.stars && this.stars.querySelector(".cover");

        if (
          !this.container ||
          !this.form ||
          !this.button ||
          !this.stars ||
          !this.cover
        ) {
          return false;
        }
        return true;
      }

      static getFormData() {
        //Выставляем оценку в input формы
        this.form.querySelector("input[name=rating]").value =
          getStarNumberUnderCursor(undefined, true, true);
        const formData = new FormData(this.form);
        const data = {};
        for (const pair of formData.entries()) {
          data[pair[0]] = pair[1];
        }

        return data;
      }
    }

    if (!Feedback.init()) {
      //If feedback is not initialized, don't do anything
      return;
    }

    //Postpone the loading of current statistics on the rating.
    //It's not an urgent operation, so we give priority to more important operations in the main stream
    setTimeout(() => {
      catalogFeedbackFormInit(true);
    }, 1000);

    //Update active stars and rating on mousemove
    Feedback.stars.addEventListener("mousemove", (event) => {
      if (!Feedback.stars.classList.contains("active")) {
        return;
      }
      setRating({ star_number: getStarNumberUnderCursor(event, true) }, true);
    });
    //Reset active stars and rating to initial values
    Feedback.stars.addEventListener("mouseleave", () => {
      Feedback.cover.style.width = Feedback.cover.dataset.rating ?? "0%";
      //console.log('leave');
    });
    //Listen to click on stars and open catalog feedback form
    Feedback.stars.addEventListener("click", (event) => {
      if (!Feedback.stars.classList.contains("active")) {
        return;
      }
      Feedback.stars.classList.remove("active");
      Feedback.form.classList.add("active");
    });
    //Send vote to server and update catalog feedback form on the form button click
    Feedback.button.addEventListener("click", (event) => {
      event.preventDefault();
      event.currentTarget.classList.add("sending");
      event.currentTarget.querySelector("span").innerText = "Отправка...";
      sendVote(Feedback.getFormData()) //Send vote to server
        .then((statistics) => {
          setRating(statistics, false); //Set rating from received statistics
        })
        .catch((error) => {
          console.error(`Ошибка отправки оценки на сервер: ${error}`);
          catalogFeedbackFormInit(true);
        });
    });

    /**
     * Initializes the catalog feedback form.
     * @param {boolean} onPageLoad - Whether to initialize the form on page load.
     * @returns {void} - Returns nothing.
     */
    function catalogFeedbackFormInit(onPageLoad = true) {
      //Get rating statistics from server
      getVotesStatistics().then((stat) => {
        if (stat) {
          if (stat.visitor_voted) {
            //If visitor voted, make the form inactive
            if (!onPageLoad) {
              //Set status 'sended' to the button and the form
              Feedback.form.classList.remove("sending");
              Feedback.form.classList.add("sended");
              Feedback.form.querySelector("span").innerText = "Отправлено";
            }
            //Reinit feedback container classes
            setTimeout(
              () => {
                Feedback.form.classList.remove("active");
                Feedback.stars.classList.remove("active");
                Feedback.container.querySelector(
                  ".catalog-feedback-header"
                ).innerText = "Благодарим за отзыв!";
                Feedback.form.classList.remove("sended");
              },
              onPageLoad ? 1 : 1000
            );
          }

          //Set rating statistics
          Feedback.stars.dataset.rating = stat.average_rating;
          Feedback.stars.dataset.ratingPercent = stat.average_rating_percent;
          Feedback.stars.dataset.votes = stat.votes_count;
          Feedback.cover.dataset.rating = Feedback.stars.dataset.ratingPercent;
          Feedback.cover.style.width = Feedback.stars.dataset.ratingPercent;
          const av = Feedback.container.querySelector(
            ".catalog-feedback-subheader > span.average-rating"
          );
          av && (av.innerHTML = Feedback.stars.dataset.rating);
          const vc = Feedback.container.querySelector(
            ".catalog-feedback-subheader > span.votes-count"
          );
          vc && (vc.innerHTML = Feedback.stars.dataset.votes);
        }
      });
    }

    /**
     * Sets the rating of a star rating element.
     * @param {number} rating - The rating to set, between 0 and 5.
     * @param {boolean} [isTemp=false] - Whether the rating is temporary or permanent.
     * @returns {void}
     */
    function setRating(params, isTemp) {
      if (Feedback.stars) {
        if (isTemp) {
          if (Feedback.stars.dataset.ratingTemp == params.star_number) {
            return;
          }
          Feedback.stars.dataset.ratingTemp = params.star_number;
          Feedback.cover.style.width =
            Feedback.stars.dataset.ratingTemp * 20 + "%";
        } else {
          catalogFeedbackFormInit(false); //Get rating statistics from server
        }
      }
    }

    /**
     * The sequence number of the svg element "star" in the rating
     * @param {Event} event - The mouse event object.
     * @param {boolean} isTemp - temporary rating (when move mouse over svg element)
     * @returns {number} - The sequence number of the svg element under the mouse cursor
     */
    function getStarNumberUnderCursor(
      event = undefined,
      isTemp = false,
      force = false
    ) {
      if (isTemp && force) {
        return Feedback.stars.dataset.ratingTemp;
      }
      if (!event) {
        return 0;
      }
      const elements = document.elementsFromPoint(event.clientX, event.clientY);
      let starNumber = 0;
      let element = elements.find((el) => el instanceof SVGElement);
      if (element) {
        //If svg element is found
        if (element.tagName !== "svg") {
          element = element.closest("svg");
        }
        if (element.tagName === "svg") {
          starNumber = findElementIndex(element) + 1;
        }
      } else {
        if (isTemp) {
          //Если элемент под курсором не svg, но пока блок с оценками, то возвращаем последний вычисленный номер svg элемента
          let starsInPoint = elements.find((el) =>
            el.classList.contains("star-rating")
          );
          if (!starsInPoint) {
            //Если указатель мыши вообще где-то за блоком, то ищем любой star-rating в "catalog-feedback"
            //Он, по идее, один и должен быть:)
            starsInPoint = Feedback.stars;
          }
          starNumber = starsInPoint.dataset.ratingTemp;
        }
      }
      return starNumber;
    }

    /**
     * Index of the element in the parent container
     * @param {Element} element
     * @returns {number} The index of the element in parent container
     */
    function findElementIndex(element) {
      const parent = element.parentElement;
      const siblings = parent.children;
      let index = -1;

      for (let i = 0; i < siblings.length; i++) {
        if (siblings[i] === element) {
          index = i;
          break;
        }
      }

      return index;
    }

    /**
     * Get voting statistics from the server
     * @param {string} url URL POST запроса
     * @returns {Promise} A Promise that resolves with the data if the status is "success" and rejects with the error message otherwise.
     * @throws {string} The text of the error received
     */
    function getVotesStatistics(
      url = "https://xn--j1aawi.xn--p1ai/ratingvoteget.json"
    ) {
      return new Promise((resolve, reject) => {
        const requestData = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        };
        fetch(url, requestData)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.status === "success") {
              resolve(data);
            } else {
              reject(data.error);
            }
          })
          .catch((error) => {
            reject(`Error fetching URL: ${error}`);
          });
      });
    }

    /**
     * Send vote to the server
     * @param {number} rating Rating (1-5)
     * @param {string} url Request url
     * @returns {Promise} A Promise that resolves with the data if the status is "success" and rejects with the error message otherwise.
     * @throws {string} The text of the error received
     */
    function sendVote(
      formData,
      url = "https://xn--j1aawi.xn--p1ai/ratingvotesend.json"
    ) {
      return new Promise((resolve, reject) => {
        const requestData = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        };
        //console.log(requestData);
        fetch(url, requestData)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.status === "success") {
              resolve(data);
            } else {
              reject(data.error);
            }
          })
          .catch((error) => {
            reject(`Error fetching URL: ${error}`);
          });
      });
    }
  },

  productPriceBlockInit() {
    const INSTALLMENT_MONTHS = 6;
    const productPrice = document.querySelector(".product-price");
    const priceAdditional =
      productPrice && productPrice.querySelector(".product-price__addition");
    const productQty =
      priceAdditional &&
      priceAdditional.querySelector(".product-price__addition-qty");
    const productThicknessSelect =
      priceAdditional &&
      priceAdditional.querySelector("#price-thickness-select");

    if (priceAdditional && productQty) {
      const buttonIncrease = productQty.querySelector(".modifier-increase");
      const buttonDecrease = productQty.querySelector(".modifier-decrease");
      const inputQty = productQty.querySelector('input[name="qty"]');
      const price = productPrice.querySelector(".product-price__current-val");
      const amount = productQty.querySelector(
        ".product-price__addition-amount > span"
      );
      const installmentAmount = priceAdditional.querySelector(
        ".installment-amount-value > span"
      );
      if (!inputQty) {
        return;
      }

      if (productThicknessSelect) {
        productThicknessSelect.addEventListener("change", (e) => {
          price.textContent =
            productThicknessSelect.options[
              productThicknessSelect.selectedIndex
            ].dataset.price;
          //price.textContent = productThicknessSelect.dataset.price;
          handleIncrementDecrement(0);
        });
      }

      function handleIncrementDecrement(increment, round = true) {
        let inputValue = parseFloat(inputQty.value);
        if (!isNaN(inputValue)) {
          inputValue = round
            ? Math.round(inputValue + increment)
            : inputValue + increment;
          //inputValue += increment;
          if (inputValue <= 0) {
            inputValue = 1;
          }
          inputQty.value = inputValue;
          if (amount && price) {
            const sumAmount = parseFloat(price.textContent) * inputValue;
            amount.textContent = sumAmount.toLocaleString("ru-ru");
            installmentAmount
              ? (installmentAmount.textContent = Math.round(
                  parseFloat(sumAmount) / INSTALLMENT_MONTHS
                ).toLocaleString("ru-ru"))
              : null;
            metrika.reachGoal("PRODUCT_ITERACTION_QTY", {
              URL: document.location.href,
            });
          }
        }
      }

      buttonIncrease &&
        buttonIncrease.addEventListener("click", () =>
          handleIncrementDecrement(1)
        );
      buttonDecrease &&
        buttonDecrease.addEventListener("click", () =>
          handleIncrementDecrement(-1)
        );
      inputQty.addEventListener("change", () =>
        handleIncrementDecrement(0, false)
      );
    }
  },

  installmentInit() {
    const installment = document.querySelector(".installment");
    const installmentAmount =
      installment && installment.querySelector("#installment_amount");
    const installmentPeriod =
      installment && installment.querySelector("#installment_period");
    const installmentSum =
      installment && installment.querySelector("#installment_sum");
    const installmentLink =
      installment && installment.querySelector("#installment-amount-link");

    if (
      !installmentAmount ||
      !installmentPeriod ||
      !installmentSum ||
      !installmentLink
    ) {
      return;
    }

    installmentLink.addEventListener("click", () => {
      metrika.reachGoal("PRODUCT_ITERACTION_INSTALLMENT", {
        URL: document.location.href,
      });
    });

    function onChangeValues() {
      const amount = parseFloat(installmentAmount.value.replace(/ /g, ""));
      const period = parseInt(installmentPeriod.value);
      const sum = Math.round(amount / period);
      setTimeout(() => {
        installmentSum.value = sum.toLocaleString("ru-ru");
        installmentSum.style.backgroundColor = "#ffe7e7";
        setTimeout(() => {
          installmentSum.style.backgroundColor = "initial";
        }, 300);
      }, 500);
    }

    installmentAmount.addEventListener("change", () => onChangeValues());
    installmentPeriod.addEventListener("change", () => onChangeValues());
  },

  deliveryInit() {
    const DELIVERY_TARIFF = 30; // Стоимость за километр.
    const WITHIN_CITY_COST = 1300; // Стоимость за километр в городе
    const deliveryCookieLifeDays = 60;

    const addressElement = document.getElementById("delivery_address_choose");
    const addressValueElement = document.querySelector(
      "#delivery_address_choose > span"
    );
    const deliveryPriceElement = document.querySelector(
      "#delivery-price-value > span"
    );
    const deliveryMapLocationInputId = "deliveryMapLocationInput";
    const deliveryMapLocationElement = document.getElementById(
      deliveryMapLocationInputId
    );
    const buttonDeliveryAddressConfirm = document.getElementById(
      "delivery-address-confirm"
    );
    const popupOverlayElement = document.getElementById(
      "delivery-popup-overlay"
    );
    const popupDeliverySelector = "#product-delivery-popup";

    let multiRoute;

    if (
      !addressElement ||
      !addressValueElement ||
      !deliveryMapLocationElement ||
      !deliveryPriceElement ||
      !buttonDeliveryAddressConfirm ||
      !popupOverlayElement
    ) {
      return;
    }

    let deliveryDetails = {};
    mainLoad();

    // loadScript("https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=773a540a-89c7-499d-9864-07098dc857a2")
    // 	.then( data  => {
    // 		console.log("Script loaded successfully", data);
    // 		mainLoad();
    // 	})
    // 	.catch( err => {
    // 		console.error(err);
    // 	});

    function mainLoad() {
      loadDeliveryDetailsFromCookie();

      function loadDeliveryDetailsFromCookie() {
        const deliveryDetailsCookie = getCookie("productDeliveryCalc");
        try {
          if (deliveryDetailsCookie) {
            deliveryDetails = JSON.parse(deliveryDetailsCookie);
            updateFields(deliveryDetails);
          }
        } catch (e) {
          console.error(e);
        }
      }

      //function updateFields(pointStart, pointEnd, pointEndShortName, pointEndFullAddress, pointEndCoordinates, distance, deliveryCost) {
      function updateFields(deliveryDetails) {
        // console.log(`==== updateFields RESULT >>>>>>`);
        // console.log(`pointStart: ${deliveryDetails.pointStartName}`);
        // console.log(`pointEnd: ${deliveryDetails.pointEndName}`);
        // console.log(`pointEndShortName: ${deliveryDetails.pointEndShortName}`);
        // console.log(`pointEndFullAddress: ${deliveryDetails.pointEndFullAddress}`);
        // console.log(`pointEndCoordinates: ${deliveryDetails.pointEndCoordinates}`);
        // console.log(`distance: ${deliveryDetails.distance}`);
        // console.log(`deliveryCost: ${deliveryDetails.deliveryCost}`);
        // console.log(`<<<<<< updateFields RESULT ====`);
        setElementValue(
          addressValueElement,
          deliveryDetails.pointEndShortName,
          { fullAddress: deliveryDetails.pointEndFullAddress }
        );
        setElementValue(deliveryPriceElement, deliveryDetails.deliveryCost);
        metrika.reachGoal("PRODUCT_ITERACTION_DELIVERY", {
          URL: document.location.href,
          DELIVERY_ADDRESS: deliveryDetails.pointEndName,
        });
      }

      addressElement.addEventListener("click", function () {
        // Проверка, был ли скрипт Яндекс.Карт уже загружен
        if (!document.querySelector('script[src*="api-maps.yandex.ru"]')) {
          const scriptYaMaps = document.createElement("script");
          scriptYaMaps.src =
            "https://api-maps.yandex.ru/2.1/?" +
            "lang=ru_RU" +
            "&load=package.full" +
            "&onload=mscDistance.Ymaps.ready" +
            "&ns=ymaps" +
            "&apikey=773a540a-89c7-499d-9864-07098dc857a2" +
            "&suggest_apikey=d67f979c-b3e1-4a87-bf25-ed019d3ad4f7";
          scriptYaMaps.addEventListener("load", function () {
            initYandexMaps(
              updateFields,
              deliveryMapLocationInputId,
              addressValueElement.dataset.fullAddress
            );

            global.popups.showPopup(popupDeliverySelector);
          });

          document.body.appendChild(scriptYaMaps);
        } else {
          initYandexMaps(
            updateFields,
            deliveryMapLocationInputId,
            addressValueElement.dataset.fullAddress
          );

          global.popups.showPopup(popupDeliverySelector);
        }
      });
      /**
       * Sets the value of an element specified by a query selector.
       *
       * @param {string} element - The query selector used to select the element.
       * @param {*} value - The value to be set on the element.
       */
      function setElementValue(element, value, dataAttr) {
        if (!value && element.dataset.defaultValue) {
          value = element.dataset.defaultValue;
        }

        switch (true) {
          case element instanceof HTMLInputElement:
            element.value = value;
            break;
          default:
            element.textContent = value;
            break;
        }
        if (dataAttr) {
          for (const key in dataAttr) {
            element.dataset[key] = dataAttr[key];
          }
        }
      }

      function initYandexMaps(
        callback,
        locationInputId,
        initAddressValue = ""
      ) {
        ymaps.ready({
          errorCallback: function (er) {
            console.error(`Ошибка ymaps.ready: ${er}`);
          },
          successCallback: function (ym) {
            // Yandex Maps API Initialization
            let shouldProcessRequest = true; // Flag to track whether the event should be processed or not

            document.querySelectorAll("ymaps").forEach(function (el) {
              el.remove();
            });

            var MySuggestLayout = ymaps.templateLayoutFactory.createClass(
              [
                "{% if state.open %}",
                '{% include "islands#suggestView" %}',
                "{% endif %}",
              ].join("")
            );

            let suggestView = new ymaps.SuggestView(locationInputId, {
              boundedBy: [
                [66.492828, 53.039925],
                [55.454833, 75.975143],
              ],
              layout: MySuggestLayout,
              width: 700,
            });
            suggestView.state.set("open", true);

            function suggestSelect(event) {
              waitingOverlayEnable();
              calcDeliveryCost(event.get("item").value);
            }
            suggestView.events.add("select", suggestSelect, this);

            deliveryMapLocationElement.addEventListener("click", function () {
              suggestView.state.set({
                open: true,
              });
            });

            buttonDeliveryAddressConfirm.addEventListener("click", function () {
              deliveryPopupClose(true);
              setCookie(
                "productDeliveryCalc",
                JSON.stringify(deliveryDetails),
                deliveryCookieLifeDays
              );
            });

            function deliveryPopupClose(confirm = false) {
              if (confirm) {
                callback(initDeliveryDetailsFromHTML(true));
              }
              //стандартный функционал
              //popupDelivery.classList.remove('_show');
              global.popups.closePopup(popupDeliverySelector);
            }

            function multiRouteRequestSuccess() {
              if (!shouldProcessRequest) {
                return;
              }
              //console.log('multiRouteRequestSuccess.toLocationParam: ' + this.toLocationParam);
              var shortestRoute;
              var shortestLength = Infinity;

              var routes = multiRoute.model.getRoutes();
              routes.forEach(function (route) {
                var routeLength = route.properties.get("distance").value;

                if (routeLength < shortestLength) {
                  shortestRoute = route;
                  shortestLength = routeLength;
                }
              });
              // Set the shortest route as active
              var activeRouteIndex = multiRoute
                .getRoutes()
                .indexOf(shortestRoute);
              multiRoute.model.setParams({ activeRoute: activeRouteIndex });

              // Получение ссылки на активный маршрут.
              var activeRoute = multiRoute.getActiveRoute();
              var routePoints = multiRoute.getWayPoints();
              let pointStart, pointEnd;
              // Access the individual route points
              const routeData = new Promise((resolve, reject) => {
                routePoints.each(function (routePoint, index) {
                  var pointCoordinates = routePoint.geometry.getCoordinates();
                  if (index == 0) {
                    //first point
                    pointStart = routePoint;
                  }
                  if (index == routePoints.getLength() - 1) {
                    //last point
                    pointEnd = routePoint;

                    ymaps
                      .geocode(pointCoordinates)
                      .then(function (res) {
                        const nearestLocation = res.geoObjects.get(0);
                        const fullAddress = nearestLocation.getAddressLine();
                        const locationName =
                          nearestLocation.getLocalities() ?? fullAddress;

                        locationFullNameToShort(locationName)
                          .then(function (locationShortName) {
                            return locationShortName;
                          })
                          .catch(function (error) {
                            return "";
                          })
                          .then(function (locationShortName) {
                            resolve({
                              pointStartName: pointStart.properties.get("name"),
                              pointEndName: locationName,
                              pointEndShortName: locationShortName,
                              pointEndFullAddress: fullAddress,
                              pointEndCoordinates: pointCoordinates,
                              distance:
                                Math.ceil(
                                  (activeRoute.properties.get("distance")
                                    .value /
                                    1000) *
                                    10
                                ) / 10,
                            });
                          });
                      })
                      .catch(function (error) {
                        reject(error); // Reject the promise if there's an error
                      });
                  }
                });
              });

              routeData.then(function ({
                pointStartName,
                pointEndName,
                pointEndShortName,
                pointEndFullAddress,
                pointEndCoordinates,
                distance,
              }) {
                setElementValue(
                  deliveryMapLocationElement,
                  pointEndFullAddress,
                  {
                    pointStartName,
                    pointEndName,
                    pointEndShortName,
                    pointEndFullAddress,
                    pointEndCoordinates,
                    distance,
                    deliveryCost: calculateCost(distance),
                  }
                );

                suggestView.state.set({
                  open: false,
                });

                waitingOverlayDisable();
              });
              shouldProcessRequest = false;
            }

            function calcDeliveryCost(toLocationParam = "") {
              //для использования внутри multiRouteRequestSuccess,
              //т.к. внутрь нее передается контекст этой функции (calcDeliveryCost)
              //this.toLocationParam = toLocationParam;
              if (toLocationParam) {
                multiRoute.model.setReferencePoints([
                  "Тюмень",
                  toLocationParam,
                ]);
                shouldProcessRequest = true;
              }
            }

            function initMultiRoute() {
              multiRoute = new ymaps.multiRouter.MultiRoute({
                referencePoints: ["Тюмень"],
              });
              multiRoute.model.events.add(
                "requestsuccess",
                multiRouteRequestSuccess,
                this
              );
            }

            /* ОБРАБОТКА */
            initMultiRoute();

            if (initAddressValue) {
              //Если адрес задан изначально, то вызываем обработчик выбора адреса из списка, чтобы все сработало
              suggestView.events.fire("select", {
                item: {
                  type: "geo",
                  value: initAddressValue,
                },
              });
            }
          },
        });
      }

      /**
       * Retrieves the short name of a location based on its full name.
       *
       * @param {string} locationFullName - The full name of the location.
       * @return {Promise<string>} The short name of the location.
       */
      function locationFullNameToShort(locationFullName, shortest = true) {
        return ymaps
          .geocode(locationFullName, { results: 1 })
          .then(function (response) {
            let shortName = response.geoObjects.get(0).properties.get("name");
            if (shortest) {
              const templates = {
                "посёлок городского типа": "пос.",
                "поселок городского типа": "пос.",
                "рабочий посёлок": "пос.",
                "рабочий поселок": "пос.",
                посёлок: "пос.",
                поселок: "пос.",
                город: "г.",
                гор: "г.",
                село: "с.",
              };
              for (var name in templates) {
                shortName = shortName.replace(
                  new RegExp(name, "g"),
                  templates[name]
                );
              }
            }
            return shortName;
            //console.log(`toLocationShortName: ${toLocationShortName}`);
          })
          .catch(function (error) {
            throw error;
          });
      }

      /**
       * Calculates the cost of delivery based on route length.
       *
       * @param {number} routeLength - The length of the route in kilometers.
       * @returns {number} The cost of delivery
       */
      function calculateCost(routeLength) {
        //const cost = Math.max(routeLength * DELIVERY_TARIFF, MINIMUM_COST);
        //const cost = Math.max(routeLength / 1000 * DELIVERY_TARIFF, MINIMUM_COST);
        const cost =
          Math.ceil((WITHIN_CITY_COST + routeLength * DELIVERY_TARIFF) / 100) *
          100; //Округляем по 100р вверх
        return cost;
      }

      function initDeliveryDetailsFromHTML(returnVariable = false) {
        const {
          pointStartName,
          pointEndName,
          pointEndShortName,
          pointEndFullAddress,
          pointEndCoordinates,
          distance,
          deliveryCost,
        } = deliveryMapLocationElement.dataset;
        deliveryDetails = {
          pointStartName,
          pointEndName,
          pointEndShortName,
          pointEndFullAddress,
          pointEndCoordinates,
          distance,
          deliveryCost,
        };
        if (returnVariable) {
          return deliveryDetails;
        }
      }

      function waitingOverlayEnable() {
        popupOverlayElement.classList.add("_show");
      }

      function waitingOverlayDisable() {
        popupOverlayElement.classList.remove("_show");
      }
    }
  },

  basketInit() {
    const btnAddToBasket = document.getElementById("btn-product-add-to-basket");
    const productQtyInput = document.querySelector(
      '.product-price__addition-qty input[name="qty"]'
    );
    const productThicknessSelect = document.getElementById(
      "price-thickness-select"
    );
    const productDetailsTag = document.querySelector(".product__details");

    if (!btnAddToBasket || !productDetailsTag) {
      return;
    }
    const urlAddToBasket = "/assets/components/minishop2/action.php";

    btnAddToBasket.addEventListener("click", function () {
      const mp_option =
        `options[mp_options][id]=${productDetailsTag.dataset.mpId}` +
        `&options[mp_options][thickness]=${
          productThicknessSelect
            ? productThicknessSelect.options[
                productThicknessSelect.selectedIndex
              ].value
            : ""
        }`;
      const payload =
        `ms2_action=cart/add&ctx=web&id=${
          productDetailsTag.dataset.id ?? "0"
        }` +
        `&count=${productQtyInput ? productQtyInput.value : "1"}&${mp_option}`;

      fetch(urlAddToBasket, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8", //т.к. при application/json $_REQUEST и $_POST пустые (автоматом данные не конвертятся в них)
          "X-Requested-With": "XMLHttpRequest", //Для эмуляции ajax-запроса (проверяется в minishop2.class.php строка 171)
        },
        body: payload,
      })
        .then((res) => {
          let result;
          try {
            result = res.json();
          } catch (error) {
            result = res.text();
          }
          return result;
        })
        //.then(res => res.text())
        .then((res) => {
          console.log(`btnAddToBasket result: ${JSON.stringify(res)}`);
        })
        .catch((error) => {
          console.error(`btnAddToBasket error: ${error.message}`);
        });
    });
  },
};

var formActions = {
  sendFormOrderData(formId, recaptchaContainerId = "") {
    var form = $(formId);
    let formJS = document.getElementById(
      formId.split("#").filter(Boolean).join("#")
    ); //Убираем символ '#', если есть (для vanilla JS)
    if (form.length <= 0 || !form.is("form")) return;

    if (formJS) {
      var sitekey = formJS.querySelector(".g-recaptcha").dataset.sitekey;
    }

    //Если есть reCAPTCHA, то отправляем запрос на проверку в google
    if (sitekey && window.grecaptcha) {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(sitekey, {
            action: "submit",
          });
          // SUCCESS!
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = "g-recaptcha-response";
          input.value = token;
          formJS.appendChild(input);

          formActions.sendFormData(formId); //Отправляем форму
        } catch (err) {
          // ERROR!
          console.log("grecaptcha error: " + err);
          return;
        }
      });
      // grecaptcha.execute(formJS.dataset.sitekey, { action: 'submit' })
      // .then(function(token) {
      // 	const input = document.createElement('input');
      // 	input.type = 'hidden';
      // 	input.name = 'g-recaptcha-response';
      // 	input.value = token;
      // 	formJS.appendChild(input); //добавляем ответ от google по капче в форму
      // });
    } else {
      console.log("Нет капчи для отправки формы");
      const GetCurrentURLService = "https://www.cloudflare.com/cdn-cgi/trace";
      const GetCountryByIPService = "https://api.country.is/";
      fetch(GetCurrentURLService)
        .then((res) => res.text())
        .then((data) => {
          let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
          let ip = data.match(ipRegex)[0];
          //console.log(ip);
          if (ip) {
            console.log(`Current IP: ${ip}`);
            const url = "https://api.country.is/";
            //const url = 'http://ip-api.com/json/'
            // const fullUrl = url + ip
            // console.log(`fullUrl: ${fullUrl}`);
            fetch(url + ip)
              .then((res) => res.json())
              .then((res) => {
                const countryShortName = res["country"];
                if (countryShortName == "RU" || countryShortName == "KZ") {
                  formActions.sendFormData(formId); //Отправляем форму, если страна Россия или Казахстан
                }
                console.log(`Country is:: ${res["country"]}`);
                console.log(`get Country result: ${JSON.stringify(res)}`);
              })
              .catch((error) => {
                console.error(`Get country error: ${error.message}`);
                formActions.sendFormData(formId); //Отправляем форму, если не удалось распознать страну
              });
          }
        })
        .catch((error) => {
          console.error(`Get IP address error: ${error.message}`);
          formActions.sendFormData(formId); //Отправляем форму, если не удалось узнать IP
        });
    }
  },

  sendFormData(formId) {
    var form = $(formId);

    //yaCounter20009113.reachGoal('TOVAR_CARD_CALC_SUBMIT', {URL_CARD_CALC_SUBMIT: document.location.href});
    //dataLayer.push({'event': 'pageView', 'pagePath': '/raschet'}); //Составная цель "Заказ расчета в товаре". Шаг - Заказали расчет
    $roistat_input = form.find("input[name='roistat']");
    if ($roistat_input.length) {
      //такой input найден в форме
      let roistatVisitId = getCookie("roistat_visit") ?? "nocookie";
      console.log("$roistat_visitId=" + roistatVisitId);
      //$roistat_input.val(window.roistat.getVisit()); //Старая реализация. Сейчас номер визита roistat подставляется из cookie
      $roistat_input.val(roistatVisitId);
    }

    $.ajax({
      type: "GET",
      url: "/formorder.json",
      data: form.serialize(),
      datatype: "json",
      success: function (data) {
        if (data.status == "success") {
          //alert(data.result);
        } else {
          alert(data.error_text);
        }
      },
      error: function (XMLHttpRequest, textStatus, errorThrown) {
        alert(textStatus + "; " + errorThrown);
      },
    });
    if (form.parents(".popup").length)
      form.parents(".popup").removeClass("_show");
    var formResult = $("#order-popup-result");
    var formResultTitleOrder = formResult.find(
      ".popup__title .popup__title-ordertype"
    );

    switch (form.data("order-type")) {
      case "raschet":
        formResultTitleOrder.text(formResultTitleOrder.data("type-raschet"));
        break;
      case "montazh":
        formResultTitleOrder.text(formResultTitleOrder.data("type-montazh"));
        break;
      case "consult":
        formResultTitleOrder.text(formResultTitleOrder.data("type-consult"));
        ($overlay = document.querySelector("._overlay")),
          $overlay.classList.add("_show");
        break;
    }

    formResult.addClass("_show");
  },
};

var metrika = {
  yaCounterNumber: 20009113,

  /**
   * A description of the entire function.
   *
   * @param {('PRODUCT_ITERACTION_QTY'|'PRODUCT_ITERACTION_INSTALLMENT'|'PRODUCT_ITERACTION_DELIVERY')} target - Goal ID
   * @param {Object} params - Session parameters
   * @return {undefined}
   */
  reachGoal(target, params = {}) {
    ym(this.yaCounterNumber, "reachGoal", target, params);
  },
};

function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

//Ссылки в содержании
$(".article__nav-list a").click(function () {
  $duration = $(this).attr("data-duration");
  if (!$duration) $duration = 200;
  $("html, body").animate(
    {
      scrollTop: $("#" + $(this).attr("data-id")).offset().top - 70,
    },
    $duration
  );
  return false;
});

//Калькулятор фасада -->
$(".calc__fasad #calc_button").on("click", function () {
  calc();
});
function calc() {
  var form_object = {
    material: $(".calc__fasad #material").val(),
    square: $(".calc__fasad #square").val(),
    windows_and_doors_number: $(".calc__fasad #windows_and_doors_number").val(),
    karniz_podshiv: $(".calc__fasad #karniz_podshiv").val(),
    karniz_dlina: $(".calc__fasad #karniz_dlina").val(),
    uteplenie_nuzhno: $(".calc__fasad #uteplenie_nuzhno").val(),
    uteplenie_type: $(".calc__fasad #uteplenie_type").val(),
  };

  $.ajax({
    type: "POST",
    url: "/calcfasad.json",
    data: form_object,
    datatype: "json",
    success: function (data) {
      if (data.status == "success") {
        $(".sum_materials .calc-fasad-cena").text(data.sum.toLocaleString());
        $(".sum_montazh .calc-fasad-cena").text(data.sum_mont.toLocaleString());
        $(".calc-fasad-result").show();
      } else {
        alert(data.error_text);
      }
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      alert(textStatus + "; " + errorThrown);
    },
  });
}
/*
function setImgDefault(){
	$('.calc-image img').attr('src','/assets/img/calculators/1.png');
};
function setImgSrc(src){
	$('.calc-image img').attr('src',src);
};
setImgDefault();


$('.calc__fasad #windows_and_doors_number').on('focus',function(){
	setImgSrc('/assets/img/calculators/2.png');
});
$('.calc__fasad #square').on('focus',function(){
	setImgSrc('/assets/img/calculators/3.png');
});
$('.calc__fasad #karniz_dlina').on('focus',function(){
	setImgSrc('/assets/img/calculators/4.png');
});


$('.calc__fasad #windows_and_doors_number').on('focusout',function(){
	setImgDefault();
});
$('.calc__fasad #square').on('focusout',function(){
	setImgDefault();
});
$('.calc__fasad #karniz_dlina').on('focusout',function(){
	setImgDefault();
});
*/

$(".calc__fasad #karniz_podshiv").on("change", function () {
  if ($(this).val() == 1) $("#karniz_dlina_tr").show();
  else $("#karniz_dlina_tr").hide();
});

$(".calc__fasad #uteplenie_nuzhno").on("change", function () {
  //$('.calc__fasad #uteplenie_nuzhno').on('click',function(){
  if ($(this).val() == 1) $("#uteplenie_type_tr").show();
  else $("#uteplenie_type_tr").hide();
});

//Калькуляор фасада <--

//Открытие ссылок расшаривания (поделиться, share) во всплывающем окне, а не в новом или в новой вкладке
const $shareLinks = document.querySelectorAll("a.share__link");
if ($shareLinks) {
  /*
	$shareLinks.forEach(link => {
		link.addEventListener('click', () => {
			let popup = window.open(link.href, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes");
			let closeF = () => {
				popup.close();
			};
			setTimeout(closeF, 30000); //Закрываем вслпывающее окно через 30 секунд, чтобы не мешало пользователю. Т.к. оно само нифига не закрывается. За 30 секунд, наверное(!), человек успеет зашарить

		});
	});
	*/
}

/**
 * Loads a script from a given URL.
 *
 * @param {string} FILE_URL - The URL of the script file to load.
 * @param {boolean} async - Optional. Determines if the script should be loaded asynchronously. Default is true.
 * @param {string} type - Optional. The type of the script. Default is "text/javascript".
 * @returns {Promise} A promise that resolves with an object {status: boolean} if the script is loaded successfully, or rejects with an object {status: boolean, message: string} if the script fails to load.
 */
const loadScript = (FILE_URL, async = true, type = "text/javascript") => {
  return new Promise((resolve, reject) => {
    try {
      const scriptEle = document.createElement("script");
      scriptEle.type = type;
      scriptEle.async = async;
      scriptEle.src = FILE_URL;

      scriptEle.addEventListener("load", (ev) => {
        resolve({ status: true });
      });

      scriptEle.addEventListener("error", (ev) => {
        reject({
          status: false,
          message: `Failed to load the script ＄{FILE_URL}`,
        });
      });

      document.body.appendChild(scriptEle);
    } catch (error) {
      reject(error);
    }
  });
};

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
